// Definitions
import type { LeadStaticDataItemType } from "client/core/lead/models";
import { LEAD_STATIC_DATA_KEYS } from "client/core/lead/models";
import { UserCountryCodesEnum } from "bus/user/models";

// Hooks
import { useLeadStaticDataFetch } from "client/core/lead/hooks/use-lead-static-data-fetch";

export type UseLeadSignupStaticDataFetchType = {
  loadingStaticData: boolean;
  phoneCodes: LeadStaticDataItemType[];
  countries: LeadStaticDataItemType[];
};

type UseLeadSignupStaticDataFetchProps = {
  country?: UserCountryCodesEnum;
};

export const useLeadSignupStaticDataFetch = (
  props?: UseLeadSignupStaticDataFetchProps,
): UseLeadSignupStaticDataFetchType => {
  const staticDataKeys = [LEAD_STATIC_DATA_KEYS.phoneCodes, LEAD_STATIC_DATA_KEYS.countries];
  const { loadingStaticData, staticData: data } = useLeadStaticDataFetch(
    staticDataKeys,
    props?.country,
  );
  const staticData = {
    phoneCodes: data?.phoneCodes || [],
    countries: data?.countries || [],
  };

  return {
    loadingStaticData,
    ...staticData,
  };
};
