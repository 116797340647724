// Core
import { z } from "zod";

// Definitions
import type { TFunction } from "client/utils/i18n/types";

// Utils
import { getFieldLabel } from "utils/forms";

export const LEAD_SIGNUP_FIELDS = {
  fullName: "fullName",
  phoneNumber: "phoneNumber",
  phonePrefix: "phonePrefix",
  email: "email",
  id: "id",
} as const;

const regExps = {
  [LEAD_SIGNUP_FIELDS.phoneNumber]: /^\d{3}[\d|\\+]\d{1,9}$/,
  [LEAD_SIGNUP_FIELDS.email]: /^[^\s@]+@[^\s@]+\.[^\s@#!?$%^&*[\](`)}{+=]{2,}$/,
};

const generalWithTranslations: Record<string, string> = {
  defaultInputPlaceholder: "inputs:defaultPlaceholders.input",
  defaultSelectPlaceholder: "inputs:defaultPlaceholders.select",
  required: "inputs:errorMessages.required",
  invalidLetters: "inputs:errorMessages.invalidLetters",
  errorMin: "inputs:errorMessages.min",
  errorMax: "inputs:errorMessages.max",
  invalidPhone: "inputs:errorMessages.invalidPhone",
  emailPlaceholder: "inputs:email.placeholder",
  emailInvalid: "inputs:errorMessages.invalidEmail",
};

export const inputConfig = {
  [LEAD_SIGNUP_FIELDS.fullName]: {
    label: getFieldLabel("fullName"),
    placeholder: getFieldLabel("fullName", true),
    required: generalWithTranslations.required,
    min: 1,
    minText: generalWithTranslations.errorMin,
    max: 35,
    maxText: generalWithTranslations.errorMax,
  },
  [LEAD_SIGNUP_FIELDS.phonePrefix]: {
    label: "",
    placeholder: generalWithTranslations.defaultSelectPlaceholder,
    required: generalWithTranslations.required,
  },
  [LEAD_SIGNUP_FIELDS.phoneNumber]: {
    label: getFieldLabel("phone"),
    placeholder: getFieldLabel("phone", true),
    required: generalWithTranslations.required,
    min: 5,
    minText: generalWithTranslations.errorMin,
    max: 13,
    maxText: generalWithTranslations.errorMax,
    pattern: regExps[LEAD_SIGNUP_FIELDS.phoneNumber],
    invalid: generalWithTranslations.invalidPhone,
  },
  [LEAD_SIGNUP_FIELDS.email]: {
    label: getFieldLabel("email"),
    placeholder: generalWithTranslations.emailPlaceholder,
    required: generalWithTranslations.required,
    pattern: regExps[LEAD_SIGNUP_FIELDS.email],
    invalid: generalWithTranslations.emailInvalid,
    min: 6,
    max: 75,
  },
};

export const leadSignupForm = {
  shape: {
    [LEAD_SIGNUP_FIELDS.fullName]: "",
    [LEAD_SIGNUP_FIELDS.phoneNumber]: "",
    [LEAD_SIGNUP_FIELDS.phonePrefix]: null,
    [LEAD_SIGNUP_FIELDS.email]: "",
  },
  schema: (t: TFunction) =>
    z.object({
      [LEAD_SIGNUP_FIELDS.fullName]: z
        .string()
        .trim()
        .min(
          inputConfig[LEAD_SIGNUP_FIELDS.fullName].min,
          t(inputConfig[LEAD_SIGNUP_FIELDS.fullName].minText, {
            label: t(inputConfig[LEAD_SIGNUP_FIELDS.fullName].placeholder).toLowerCase(),
            count: inputConfig[LEAD_SIGNUP_FIELDS.fullName].min,
          }),
        )
        .max(
          inputConfig[LEAD_SIGNUP_FIELDS.fullName].max,
          t(inputConfig[LEAD_SIGNUP_FIELDS.fullName].maxText, {
            label: t(inputConfig[LEAD_SIGNUP_FIELDS.fullName].placeholder).toLowerCase(),
            count: inputConfig[LEAD_SIGNUP_FIELDS.fullName].max,
          }),
        )
        .refine((v) => v.trim().length > 0, {
          message: t(inputConfig[LEAD_SIGNUP_FIELDS.fullName].required),
        }),
      [LEAD_SIGNUP_FIELDS.phonePrefix]: z
        .object(
          {
            id: z.number(),
            label: z.string(),
            value: z.string(),
            icon: z.string(),
          },
          {
            required_error: t(inputConfig[LEAD_SIGNUP_FIELDS.phonePrefix].required),
            invalid_type_error: t(inputConfig[LEAD_SIGNUP_FIELDS.phonePrefix].required),
          },
        )
        .required({
          id: true,
          label: true,
          value: true,
        }),
      [LEAD_SIGNUP_FIELDS.phoneNumber]: z
        .string({
          required_error: t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].required),
          invalid_type_error: t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].required),
        })
        .trim()
        .min(
          inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].min,
          t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].minText, {
            label: t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].label).toLowerCase(),
            count: inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].min,
          }),
        )
        .max(
          inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].max,
          t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].maxText, {
            label: t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].label).toLowerCase(),
            count: inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].max,
          }),
        )
        .regex(
          inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].pattern,
          t(inputConfig[LEAD_SIGNUP_FIELDS.phoneNumber].invalid),
        ),
      [LEAD_SIGNUP_FIELDS.email]: z
        .string()
        .trim()
        .regex(
          inputConfig[LEAD_SIGNUP_FIELDS.email].pattern,
          t(inputConfig[LEAD_SIGNUP_FIELDS.email].invalid),
        )
        .refine((v) => v.trim().length >= inputConfig[LEAD_SIGNUP_FIELDS.email].min, {
          message: inputConfig[LEAD_SIGNUP_FIELDS.email].required,
        }),
    }),
};
