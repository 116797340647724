// Core
import { useContext } from "react";

// Components
import { LeadSignupProfileForm } from "client/core/lead-signup-profile/components/lead-signup-profile-form";

// Contexts
import { AuthUserDraftContext } from "contexts/auth-user-draft";

// Domains
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Hooks
import { useLeadSignupCreate } from "client/core/lead-signup/hooks/use-lead-signup-create";
import { useLeadSignupStaticDataFetch } from "client/core/lead-signup/hooks/use-lead-signup-static-data-fetch";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { introLead } from "client/core/lead-signup/data";

type LeadSignupRequestPropsType = {
  uniqKey?: string;
  testId?: string;
  type: "signup" | "modal";
};

export const LeadSignupRequest = ({ uniqKey, testId, type }: LeadSignupRequestPropsType) => {
  const { t } = useTranslation();

  const { userCountry, onUpdateUserCountryByPhone } = useUserCountry();
  const { loadingLeadSignupCreate, onCreateLeadSignup } = useLeadSignupCreate();
  const { signupRequest, setSignupRequest } = useContext(AuthUserDraftContext);
  const { loadingStaticData, phoneCodes, countries } = useLeadSignupStaticDataFetch({
    country: userCountry,
  });
  if (type === "signup") {
    return (
      <LeadSignupProfileForm
        testId={testId}
        initialValues={signupRequest}
        title={t("intro:registerTitle")}
        uniqKey={uniqKey}
        loadingCreate={loadingLeadSignupCreate}
        loadingStaticData={loadingStaticData}
        country={userCountry}
        countries={countries}
        phoneCodes={phoneCodes}
        onSubmit={onCreateLeadSignup}
        onSaveLocalStorage={setSignupRequest}
        onUpdateUserCountry={onUpdateUserCountryByPhone}
      />
    );
  }

  if (type === "modal") {
    return (
      <LeadSignupProfileForm
        testId={testId}
        initialValues={signupRequest}
        title={t(introLead.modalTitle)}
        uniqKey={uniqKey}
        loadingCreate={loadingLeadSignupCreate}
        loadingStaticData={loadingStaticData}
        country={userCountry}
        countries={countries}
        phoneCodes={phoneCodes}
        onSubmit={onCreateLeadSignup}
        onSaveLocalStorage={setSignupRequest}
        onUpdateUserCountry={onUpdateUserCountryByPhone}
      />
    );
  }

  return null;
};
