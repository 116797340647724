// Core
import { AxiosRequestConfig } from "axios";
import QS from "qs";

// Definitions
import type { HttpResponse } from "models/Http";
import type { LeadStaticDataFetchParams, LeadStaticDataInnerShape } from "client/core/lead/models";

// Utils
import { http } from "api/network-provider";
import { HEADER_COUNTRY } from "utils/constants";

export const leadService = Object.freeze({
  fetchPublic: (payload: LeadStaticDataFetchParams) => {
    const { params, meta } = payload;
    const config = {
      params,
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, { encode: false }),
    };

    return http.get<HttpResponse<LeadStaticDataInnerShape>>("/dropdown-options/public", config);
  },
});
