// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { HttpErrorType } from "models/Http";
import type { BaseQueryKey } from "models/Base";
import type { FormSubmitParams } from "models/Forms";
import type { LeadType } from "client/core/lead-signup-profile/models";
import { GTMTypesEnum, ParametersEnum } from "client/utils/gtm/gtm.types";
import type { UserCountryCodesKeys } from "bus/user/models";
import type { LeadStateType } from "client/core/lead-signup/store";

// Domains
import { initialLeadSignupState, leadSignupKeys } from "client/core/lead-signup/store";

// Utils
import { book } from "init/book";
import { queryClient } from "init/queryClient";
import { fillServerErrorsToForm } from "utils/forms";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { leadSignupService } from "client/core/lead-signup/service";
import { gtmService } from "client/utils/gtm";
import { getLocaleByCountry } from "utils/locales";

type UseLeadSignupCreateType = {
  loadingLeadSignupCreate: boolean;
  onCreateLeadSignup: (values: FormSubmitParams<LeadType>) => void;
};

export const useLeadSignupCreate = (): UseLeadSignupCreateType => {
  const router = useRouter();

  const queryKey: BaseQueryKey = [leadSignupKeys.lead];

  const leadProps = useMutationAsync<LeadType, LeadType, unknown, BaseQueryKey>({
    key: queryKey,
    name: "createLeadSignupMutation",
    fetcher: leadSignupService.createLead,
    options: {
      onSuccess: async (data) => {
        if (!data) {
          return;
        }
        queryClient.setQueryData<LeadStateType>(queryKey, (prevState) => {
          if (!prevState?.lead) {
            return initialLeadSignupState;
          }
          return {
            ...prevState,
            lead: {
              ...prevState.lead,
              ...data,
            },
          };
        });

        const hash = data.id;
        const { locale: newLocale } = getLocaleByCountry(
          data.country?.value as UserCountryCodesKeys,
        );

        await router.push(
          {
            pathname: book.signupProfile,
            query: { hash },
          },
          void 0,
          { locale: newLocale },
        );
      },
    },
  });

  const onCreateLeadSignup = useCallback(
    (data: FormSubmitParams<LeadType>) => {
      void (async () => {
        try {
          await leadProps.mutateAsync(data.values);
          gtmService?.event(GTMTypesEnum.registration, { [ParametersEnum.registration_step]: 1 });
        } catch (error) {
          const errs = (error as HttpErrorType).errors;
          if (error instanceof Object && errs) {
            fillServerErrorsToForm(errs, data?.acts?.setError);
          }
        }
      })();
    },
    [leadProps],
  );

  return {
    loadingLeadSignupCreate: leadProps.isLoading,
    onCreateLeadSignup,
  };
};
