// Definitions
import type { LeadStaticDataInnerShape, LeadStaticDataType } from "client/core/lead/models";

// Domains
import { leadKeys } from "client/core/lead/store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { leadService } from "client/core/lead/service";
import { errorHandler404 } from "utils/error-handlers";

type StaticDataQueryKey = [string, { types: LeadStaticDataType[] }];

export type UseLeadStaticDataFetchType = {
  loadingStaticData: boolean;
  staticData: LeadStaticDataInnerShape | null;
};

export const useLeadStaticDataFetch = (
  values: LeadStaticDataType[],
  options?: string,
): UseLeadStaticDataFetchType => {
  const keyParams = { types: values, ...(options && { options }) };
  const params = { types: values };
  const fetchStaticData = useQueryAsync<
    LeadStaticDataInnerShape,
    LeadStaticDataInnerShape,
    StaticDataQueryKey
  >({
    key: [leadKeys.staticData, keyParams],
    name: "fetchLeadStaticDataQuery",
    fetcher: leadService.fetchPublic,
    fetcherPayload: { params },
    handlerError404: errorHandler404,
    options: {
      refetchOnMount: "always",
    },
  });

  return {
    loadingStaticData: fetchStaticData.isLoading || false,
    staticData: fetchStaticData.data || null,
  };
};
