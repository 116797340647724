// Core
import z from "zod";

// Definitions
import type { HttpRequestParams } from "models/Http";

export const LEAD_STATIC_DATA_KEYS = {
  countries: "countries",
  phoneCodes: "phoneCodes",
  quantityAutolifts: "quantityAutolifts",
  businessType: "businessType",
  quantityEmployees: "quantityEmployees",
} as const;

const staticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});

export type LeadStaticDataItemType = z.infer<typeof staticDataItemSchema>;

export type LeadStaticDataType = keyof typeof LEAD_STATIC_DATA_KEYS;

export type LeadStaticDataInnerShape = {
  [K in LeadStaticDataType]: LeadStaticDataItemType[];
};

export type LeadStaticDataFetchParams = HttpRequestParams<{ types: LeadStaticDataType[] }>;
