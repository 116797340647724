// Definitions
import type { LeadType } from "client/core/lead-signup/models";

export const leadSignupKeys = {
  lead: "lead",
  verifiedLead: "lead/invited",
  invitedEmail: "lead/invited/email",
};

export type LeadStateType = {
  lead: LeadType | null;
};

export const initialLeadSignupState: LeadStateType = {
  lead: null,
};
