// Core
import React, { PropsWithChildren } from "react";
import cx from "classnames";

// Components
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./Styles.module.less";

type FormHelpTextProps = PropsWithChildren<{
  children: JSX.Element | JSX.Element[] | string;
  testId?: string;
  type?: "primary" | "second";
  hasMargin?: boolean;
}>;
/*
 TODO: move component to common from ui
*/

export const FormHelpText = (props: FormHelpTextProps) => {
  const { children, testId = "form-help-text", type = "primary", hasMargin } = props;
  const helpTextStyle = cx(st["help-text"], {
    [st["help-text-gutter-bottom"]]: hasMargin,
  });

  if (!type) {
    return null;
  }

  return (
    <>
      {type === "primary" ? (
        <Text
          tag="p"
          testId={testId}
          box="full"
          size="14"
          color="gray-900"
          font="system"
          alignmentHorizontal="center"
          className={helpTextStyle}
        >
          <>{children}</>
        </Text>
      ) : null}
      {type === "second" ? (
        <Text
          tag="p"
          box="full"
          testId={testId}
          size="12"
          color="gray-700"
          font="system"
          alignmentHorizontal="center"
          className={helpTextStyle}
        >
          <>{children}</>
        </Text>
      ) : null}
    </>
  );
};
