// Core
import { AxiosRequestConfig } from "axios";
import QS from "qs";

// Definitions
import type { HttpResponse } from "models/Http";
import type {
  LeadHash,
  LeadParamsType,
  LeadEmailType,
  LeadEmailParamsType,
  LeadType,
} from "client/core/lead-signup/models";

// Utils
import { http } from "api/network-provider";
import { HEADER_COUNTRY } from "utils/constants";

export const leadSignupService = Object.freeze({
  createLead: (payload: LeadParamsType) => {
    const { data } = payload;
    return http.post<HttpResponse<LeadType>>("/lead", data);
  },
  createVerifiedLead: (payload: LeadParamsType) => {
    const { data, meta } = payload;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.post<HttpResponse<LeadHash>>("/lead/invited", data, config);
  },
  fetchInvitedLeadEmail: (payload: LeadEmailParamsType) => {
    const { params, meta } = payload;
    const config = {
      params,
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, { encode: false }),
    };
    return http.get<HttpResponse<LeadEmailType>>("/lead/invited/email", config);
  },
});
